:root {
  --background: #fff;
  --primary: #4b8efd;
  --status-font-family: "DMSans-Medium", Helvetica;
  --status-font-size: 10px;
  --status-font-style: normal;
  --status-font-weight: 500;
  --status-letter-spacing: .5px;
  --status-line-height: normal;
  --warning: #e39251;
}

.element {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.element .div {
  width: 1920px;
  height: 10088px;
  position: relative;
}

.element .intro {
  background-color: #fff;
  width: 1920px;
  height: 900px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap {
  width: 1795px;
  height: 900px;
  position: relative;
  left: 125px;
}

.element .overlap-group {
  width: 1795px;
  height: 900px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .overlap-2 {
  width: 1156px;
  height: 900px;
  position: absolute;
  top: 0;
  left: 639px;
}

.element .text-wrapper {
  color: #2f2f2f;
  letter-spacing: -.39px;
  height: 34px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 70px;
  left: 0;
}

.element .text-wrapper-2 {
  color: #2f2f2f;
  letter-spacing: -.39px;
  height: 34px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 70px;
  left: 717px;
}

.element .frame {
  width: 939px;
  height: 900px;
  position: absolute;
  top: 0;
  left: 217px;
}

.element .image {
  width: 939px;
  height: 742px;
  position: absolute;
  top: 158px;
  left: 0;
}

.element .group {
  width: 760px;
  height: 490px;
  position: absolute;
  top: 230px;
  left: 0;
}

.element .context {
  width: 764px;
  height: 298px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .text-wrapper-3 {
  color: #2f2f2f;
  letter-spacing: -2.39px;
  white-space: nowrap;
  height: 86px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 80px;
  font-weight: 700;
  line-height: 86.4px;
  position: absolute;
  top: 0;
  left: 0;
}

.element .p {
  color: #777;
  letter-spacing: -.29px;
  width: 760px;
  height: 180px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 118px;
  left: 0;
}

.element .scope-of-work {
  width: 714px;
  height: 120px;
  position: absolute;
  top: 370px;
  left: 2px;
}

.element .overlap-3 {
  width: 451px;
  height: 156px;
  position: absolute;
  top: 0;
  left: -32px;
}

.element .UIX {
  width: 287px;
  height: 120px;
  position: absolute;
  top: 36px;
  left: 0;
}

.element .SM {
  width: 180px;
  height: 56px;
  position: absolute;
  top: 64px;
  left: 271px;
}

.element .overlap-4 {
  background-color: #614ad3;
  border-radius: 28px;
  width: 178px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-4 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  height: 33px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  left: 65px;
}

.element .overlap-group-wrapper {
  background-color: #fff;
  border-radius: 23.06px;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 5px;
  left: 6px;
}

.element .overlap-group-2 {
  width: 21px;
  height: 21px;
  position: relative;
  top: 12px;
  left: 12px;
}

.element .line {
  width: 5px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 8px;
}

.element .img {
  width: 21px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.element .text-wrapper-5 {
  color: #2f2f2f;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 32px;
}

.element .WD {
  width: 281px;
  height: 56px;
  position: absolute;
  top: 64px;
  left: 433px;
}

.element .overlap-5 {
  background-color: #2934d0;
  border-radius: 28px;
  width: 279px;
  height: 56px;
  position: relative;
}

.element .text-wrapper-6 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  height: 33px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  left: 64px;
}

.element .overlap-wrapper {
  width: 121px;
  height: 74px;
  position: absolute;
  top: 51px;
  left: 0;
}

.element .overlap-6 {
  width: 117px;
  height: 74px;
  position: relative;
}

.element .gaus-mann {
  color: #2f2f2f;
  letter-spacing: .67px;
  width: 109px;
  font-family: DM Sans, Helvetica;
  font-size: 33.7px;
  font-weight: 700;
  line-height: 32.5px;
  position: absolute;
  top: 8px;
  left: 0;
}

.element .text-wrapper-7 {
  color: #2f2f2f;
  letter-spacing: -.67px;
  white-space: nowrap;
  width: 17px;
  font-family: DM Sans, Helvetica;
  font-size: 33.7px;
  font-weight: 700;
  line-height: 48.7px;
  position: absolute;
  top: 0;
  left: 100px;
}

.element .website {
  width: 1920px;
  height: 4600px;
  position: absolute;
  top: 900px;
  left: 0;
}

.element .outro {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 5500px;
  left: 0;
}

.element .img-2 {
  width: 1920px;
  height: 4048px;
  position: relative;
}

.element .div-wrapper {
  background-image: url("rectangle-122.2356f5d3.svg");
  background-size: 100% 100%;
  width: 1920px;
  height: 540px;
  position: relative;
}

.element .logo-wrapper {
  background-image: url("mask-group.4a0d5060.png");
  background-size: 100% 100%;
  height: 540px;
  position: relative;
}

.element .logo {
  width: 144px;
  height: 32px;
  position: absolute;
  top: 391px;
  left: 1657px;
}

/*# sourceMappingURL=index.14a797bc.css.map */
