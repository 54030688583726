:root {
  --background: rgba(255, 255, 255, 1);
  --primary: rgba(75, 142, 253, 1);
  --status-font-family: "DMSans-Medium", Helvetica;
  --status-font-size: 10px;
  --status-font-style: normal;
  --status-font-weight: 500;
  --status-letter-spacing: 0.5px;
  --status-line-height: normal;
  --warning: rgba(227, 146, 81, 1);
}
